import { useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '../ducksHook';
import {
  dashboardActions,
  selectedDashboard,
  selectedDashboardData,
  selectedDashboardFailed,
} from '../slices/dashboard.slice';

import { DashboardValue, ErrorValue } from '../types';

type Operators = {
  data: {};
  onDrawerEnable: (params: boolean) => void;
  failed: ErrorValue;
  isLoading: boolean;
  onDashboard: (params: DashboardValue) => void;
  onActiveDrawerChange: (params: string) => void;
};

export const useDashboardService = (): Readonly<Operators> => {
  const dispatch = useAppDispatch();

  return {
    data: useAppSelector(selectedDashboardData),
    failed: useAppSelector(selectedDashboardFailed),
    isLoading: useAppSelector(selectedDashboard),
    onDashboard: useCallback((params: DashboardValue) => {
      dispatch(dashboardActions.dashboardRequest(params));
    }, [dispatch]),
    onDrawerEnable: useCallback((params: any) =>  
      dispatch(dashboardActions.drawerEnable(params)),
    [dispatch]),
    onActiveDrawerChange: useCallback(
      (params) =>  dispatch(dashboardActions.activeDrawer(params)),
    [dispatch]),
  };
};

export default useDashboardService;