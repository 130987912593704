import _ from 'lodash';
import moment from "moment-timezone";
import { add, multiply, substract } from './computation-helper';

const descendingComparator = (a: any, b: any, orderBy: string) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (order: any, orderBy: string) => {
  return order === 'descending'
    ? (a: any, b: any) => descendingComparator(a, b, orderBy)
    : (a: any, b: any) => -descendingComparator(a, b, orderBy);
};

// ARRAY FILTER BY SEARCH AND ORDERBY
export const applySortFilter = (array: any, comparator: any, query: string = "", properties: string = "", properties2: string = "") => {
  const stabilizedThis = array.map((el: any, index: number) => [el, index]);
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });

  if (query) {
    return _.filter(array, (_user) => 
      `${_user[properties] || ""}`.toLowerCase().indexOf(query.toLowerCase()) !== -1 || 
      `${_user[properties2] || ""}`.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }

  return stabilizedThis.map((el: any) => el[0]);
};

// AFFIX SIGN LOGO AND DATE POSITION BASE ON ROTATION
export const signLogoAndDatePosition = (
  config: any,
  heigh1_3rd: number,
  heigh1_7th: number,
  dateFontSize: number,
  height: number
) => {
  switch (Math.trunc(config.rotation || 0)) {
    case 90:
      const xx = substract(config.x, config.height) + heigh1_7th;
      const yy = substract(height, config.y);

      return {
        x: xx,
        y: yy - heigh1_7th,
        logoX: xx + heigh1_3rd * 0.2,
        logoY: yy - config.width * 0.054,
        textX: xx + heigh1_3rd / 2 - dateFontSize / 2,
        textY: yy - config.width * 0.43,
      };
    case -90:
      const xxx = add(config.x, config.height) - heigh1_7th;
      const yyy = substract(height, config.y);
      return {
        x: xxx,
        y: yyy - heigh1_7th,
        logoX: xxx - heigh1_3rd * 0.2,
        logoY: yyy + config.width * 0.054,
        textX: xxx - heigh1_3rd / 2 + dateFontSize / 2,
        textY: yyy + config.width * 0.43,
      };
    default:
      const y = substract(height, add(config.y, config.height));

      return {
        x: config.x,
        y: y,
        logoX: config.x + config.width * 0.054,
        logoY: y + heigh1_7th + heigh1_3rd * 0.2,
        textX: config.x + config.width * 0.43,
        textY: y + heigh1_7th + heigh1_3rd / 2 - dateFontSize / 2,
      };
  }
};

// AFFIX SIGNATURE POSITION BASE ON ROTATION
export const signaturePosition = (image: any, config: any, height: number) => {
  const scaled = image.scaleToFit(config.width, config.height * 0.67);

  switch (Math.trunc(config.rotation || 0)) {
  case 90:
    return {
      x: substract(config.x, config.height),
      y: substract(height, config.y + ((config.width - scaled.width) / 2)),
      width: scaled.width,
      height: scaled.height,
    };
  case -90:
    return {
      x: add(config.x, config.height),
      y: substract(height, config.y - ((config.width - scaled.width) / 2)),
      width: scaled.width,
      height: scaled.height,
    };
  default:
    return {
      x: config.x + ((config.width - scaled.width) / 2),
      y: substract(height, add(config.y, config.height)),
      width: scaled.width,
      height: scaled.height,
    };
  }
};

// AFFIX TEXT POSITION BASE ON ROTATION (SIGNATURE-NAME, SIGNATURE-DATE, SIGNATURE-NAME-DESIGNATION)
export const signTextPosition = (
  config: any,
  textConfig: any,
  height: number
) => {
  switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        x: substract(
          config.x,
          textConfig.y + multiply(textConfig.fontSize, 0.81)
        ),
        y: substract(height, config.y),
      };
    case -90:
      return {
        x: add(config.x, textConfig.y + multiply(textConfig.fontSize, 0.81)),
        y: substract(height, config.y),
      };
    default:
      return {
        x: config.x,
        y: substract(
          height,
          add(config.y, textConfig.y + multiply(textConfig.fontSize, 0.81))
        ),
      };
  }
};

// AFFIX TEXTBOX POSITION BASE ON ROTATION
export const signTextBoxPosition = (
  config: any,
  textConfig: any,
  height: number
) => {
  switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        x: substract(config.x, multiply(textConfig.fontSize, 1.35)),
        y: substract(height, config.y + 2),
        boxX: substract(config.x, config.height),
        boxY: substract(height, config.y),
      };
    case -90:
      return {
        x: add(config.x, multiply(textConfig.fontSize, 1.35)),
        y: substract(height, config.y),
        boxX: add(config.x, config.height),
        boxY: substract(height, config.y),
      };
    default:
      return {
        x: config.x + 2,
        y: substract(height, config.y + multiply(textConfig.fontSize, 1.35)),
        boxX: config.x,
        boxY: substract(height, config.y + config.height),
      };
  }
};

// AFFIX CHECKBOX POSITION BASE ON ROTATION
export const signCheckBoxPosition = (
  config: any,
  inputConfig: any,
  checkConfig: any,
  height: number
) => {
  switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        checkX: config.x - checkConfig.width,
        checkY: substract(height, checkConfig.y + checkConfig.height),
        textX: substract(config.x, multiply(inputConfig.fontSize, 1.35)),
        textY: substract(
          height,
          config.y + 2 + checkConfig.width + checkConfig.marginRight
        ),
      };
    case -90:
      return {
        checkX: config.x,
        checkY: substract(height, checkConfig.y),
        textX: add(config.x, multiply(inputConfig.fontSize, 1.35)),
        textY: substract(
          height,
          config.y - (checkConfig.width + checkConfig.marginRight + 2)
        ),
      };
    default:
      return {
        checkX: checkConfig.x,
        checkY: substract(height, checkConfig.y + checkConfig.height),
        textX: config.x + 2 + checkConfig.width + checkConfig.marginRight,
        textY: substract(
          height,
          config.y + multiply(inputConfig.fontSize, 1.35)
        ),
      };
  }
};

// AFFIX TEXT POSITION BASE ON ROTATION (FULL-NAME, DATE-TIME, DESIGNATION)
export const signSingleTextPosition = (
  config: any,
  textConfig: any,
  height: number
) => {
  switch (Math.trunc(config.rotation || 0)) {
    case 90:
      return {
        x: substract(config.x, multiply(textConfig.fontSize, 0.81)),
        y: substract(height, config.y),
      };
    case -90:
      return {
        x: add(config.x, multiply(textConfig.fontSize, 0.81)),
        y: substract(height, config.y),
      };
    default:
      return {
        x: config.x,
        y: substract(height, config.y + multiply(textConfig.fontSize, 0.81)),
      };
  }
};

// GET CONFIG BASE ON TYPES
export const getTextConfig = (field: any) => {
  switch (field.type) {
    case 'designation':
      return field.designationConfig;
    case 'signature-date':
    case 'date-time':
      return field.dateConfig;
    case 'signature-name':
    case 'signature-name-designation':
    case 'full-name':
    case 'doc-stamp':
      return field.nameConfig;
    case 'textbox':
    case 'checkbox':
      return field.inputConfig;
  }
};

// FOR GENERATING MANIFEST - GET CORRESPONDING VALUE FROM LABEL
export const getSinatoryAndApproverValue = (label: string, value: any) => {
  const timezone = value.timezone || moment.tz.guess();

  switch (label) {
    case 'Name:':
      return value.name;
    case 'Email:':
      return value.email;
    // case 'Name:':
    //   return `${value.name} | ${value.email}`;
    case 'Login Type:':
      return value.loginType;
    case 'Signature Type:':
      return value.signatureType || 'N/A';
    case 'IP Address:':
      return value.ip;
    case 'Viewed:':
      return `${moment(value.viewed).tz(timezone).format("MMM DD, YYYY | hh:mm A ([GMT] Z)")}`;
    case 'Signed/Approved:':
      return `${moment(value.executed).tz(timezone).format("MMM DD, YYYY | hh:mm A ([GMT] Z)")}`;
    case 'Signature ID:':
      return value.signatureId || 'N/A';
    case 'Region/City:':
      return value.regionCity;
  }
};

// CREATE POSITION FOR THE PDF VIEW
export const getSignatoryLabels = () => {
  const labels = [
    {
      label1: 'Name:',
      l1_x: 37,
      l1_value_x: 70,
      size: 10,
      label2: 'Email:',
      l2_x: 325,
      l2_value_x: 356,
    },
    {
      label1: 'Viewed:',
      l1_x: 37,
      l1_value_x: 77,
      size: 10,
      label2: 'Login Type:',
      l2_x: 325,
      l2_value_x: 382,
    },
    {
      label1: 'Signed/Approved:',
      l1_x: 37,
      l1_value_x: 125,
      size: 10,
      label2: 'Signature Type:',
      l2_x: 325,
      l2_value_x: 400,
    },
    {
      label1: 'Signature ID:',
      l1_x: 37,
      l1_value_x: 100,
      size: 10,
      label2: 'IP Address:',
      l2_x: 325,
      l2_value_x: 383,
    },
  ];

  return labels;
};

// FILTER PARTIES ARRAY BY ROLE = APPROVE AND SORTED BY EXECUTED
export const getPartiesApprove = (document: any = {}) => {
  const parties = document?.workflow?.parties ?? [];
  const result = _.chain(parties)
    .filter((item: any) => item.executed && item.trackId === 'notary')
    .orderBy(['executed'], ['asc'])
    .value();

  return result;
};

// GET AUTHOR FROM 'workflow.auditTrail' array
export const getAuthorFromAuditTrail = (document: any = {}) => {
  const auditTrail = document?.workflow?.auditTrail ?? [];

  return auditTrail.find((item: any) => item.details === 'Created') || {};
};

// GET COMPLETED FROM 'workflow.auditTrail' array
export const getCompletedFromAuditTrail = (document: any = {}) => {
  const auditTrail = document?.workflow?.auditTrail ?? [];

  return auditTrail.find((item: any) => item.details === 'Completed') || {};
};

// FILTER PARTIES ARRAY BY ROLE = SIGN AND SORTED BY EXECUTED
export const getPartiesHasExecute = (document: any = {}) => {
  const parties = document?.workflow?.parties ?? [];
  const result = _.chain(parties)
    .filter((item: any) => item.executed && item.role === 'SIGN')
    .orderBy(['executed'], ['asc'])
    .value();

  return result;
};

// CHECK IF ALL PARTIES ARE SIGNED EXCEPT CURRENT SIGNIE
export const checkPartiesSigned = (document: any = {}) => {
  const parties = document?.workflow?.parties ?? [];
  const result = parties.filter((item: any) => item.executed || item.role === "COPY");

  return result.length === parties.length;
};


// CHECK ALL PARTIES WITH ROLE SIGN ARE HAVE PUT SIGNATURE ON FIELDS
export const areAllSignPartiesIncluded = (signatures: any[], parties: any[]) => {
  const requireTypes = ["signature","signature-date","signature-name","signature-name-designation"];
  const signatureEmails = signatures.reduce((result: any[], value: any) => {
    value.layerFields.forEach((item: any) => {
      if(requireTypes.includes(item.type)){
        result.push(item.email);
      }
    });
    return result;
  },[]);

  parties = parties.filter((item) => item.role === "SIGN");

  // Check if all party emails are included in the signature emails
  return parties.every(party => signatureEmails.includes(party.email));
};

// FILTER DOCUMENTS INCLUDING SUB FILES VIA SEARCH
export const searchByName = (documents: any[], query: string, path: string = "name") => {
  const filteredData = documents.filter(item => {
    const parentNameMatch = item[path]?.toLowerCase().includes(query.toLowerCase());

    // Check if any name in the child array matches the query
    const childArrayMatch = (item?.children ?? []).some((child: any) => child[path]?.toLowerCase().includes(query.toLowerCase()));

    return parentNameMatch || childArrayMatch;
  });

  return filteredData.map(item => {
    // If there is a match in the child array, filter the child array
    if (item.children && item?.children.some((child: any) => child[path]?.toLowerCase().includes(query.toLowerCase()))) {
      const filteredChildArray = item.children.filter((child: any) => child[path]?.toLowerCase().includes(query.toLowerCase()));
      return { ...item, children: filteredChildArray };
    } else {
      return item;
    }
  });
};
