import React, { useContext, useMemo, useState } from "react";

// Import Worker
import { ScrollMode, Viewer, Worker } from "@react-pdf-viewer/core";

// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
// Import styles of default layout plugin
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import "@react-pdf-viewer/print/lib/styles/index.css";
import "@react-pdf-viewer/zoom/lib/styles/index.css";
import { useAppSelector } from "@src/ducks/ducksHook";
import { selectedViewDocument } from '@src/ducks/slices/signsecure.slice';
import { disableScrollPlugin } from "@src/utils/pdf-plugin";
import { usePDFScale } from "@src/utils/screensize-helper";
import { getViewport } from "@src/utils/signature-helper";
import { ActivityIndicator, LayoutChangeEvent, ScrollView, View } from "react-native";
import { CanvasContext } from '../../screens/live-request/submit-to-notary/provider/canvas';
import { PluginContext } from '../../screens/live-request/submit-to-notary/provider/plugin';
import HeaderPageNavigation from "./header-page-pagination";
import useStyles from "./styles.css";

function ViewerScreen() {
  const document = useAppSelector(selectedViewDocument);
  const styles = useStyles();
  const scale = usePDFScale();
  const viewRef = React.useRef<any>();
  const [maxWidth, setMaxWidth] = useState(900);
  const {
    zoomPluginInstance,
    printPluginInstance,
    pageNavigationPluginInstance,
  } = useContext(PluginContext);
  const disableScrollPluginInstance = disableScrollPlugin();
  const { canvas } = useContext(CanvasContext);
  const { CANVAS_WIDTH, CANVAS_HEIGHT } = useMemo(() => getViewport(canvas, scale),
    [canvas, scale]
  );

  const onLayout = (e: LayoutChangeEvent) => {
    setMaxWidth(e.nativeEvent.layout.width);
  };

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <View style={[styles.container]}>
        <View onLayout={onLayout} style={[styles.v_pdf_wrapper]}>
          <HeaderPageNavigation />
          <ScrollView
            horizontal
            showsHorizontalScrollIndicator={true}
            contentContainerStyle={[
              styles.scroll_horizontal,
              {
                maxWidth: maxWidth,
                width: CANVAS_WIDTH,
                height: CANVAS_HEIGHT,
              },
            ]}
          >
            <View
              ref={viewRef}
              style={[
                styles.v_viewer,
                {
                  width: CANVAS_WIDTH,
                  height: CANVAS_HEIGHT,
                },
              ]}
            >
              { document.uri &&
                <Viewer
                  fileUrl={document.uri}
                  defaultScale={scale}
                  enableSmoothScroll={false}
                  scrollMode={ScrollMode.Page}
                  plugins={[
                    disableScrollPluginInstance,
                    pageNavigationPluginInstance,
                    zoomPluginInstance,
                    printPluginInstance,
                  ]}
                  renderLoader={() => (
                    <View
                      style={{
                        paddingTop: 100,
                        width: CANVAS_WIDTH,
                        height: CANVAS_HEIGHT,
                      }} >
                      <ActivityIndicator animating color="black" size={30} />
                    </View>
                  )}
                />
              }
            </View>
          </ScrollView>
        </View>
      </View>
    </Worker>
  );
}

export default ViewerScreen;
